import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useAuth } from 'contexts/AuthContext'

function PrivateRoute ({ component: Component, path, ...rest }) {
  const { currentUser } = useAuth()

  const renderPage = (props) => {
    if (currentUser) {
      return <Component {...props} />
    }
    else {
      return <Redirect to='/sign_in' />
    }
  }

  return <Route {...rest} render={renderPage} />
}

export default PrivateRoute
