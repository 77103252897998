import React from 'react'
import { Form } from 'formik'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Field from 'fields/Field'

import ImageUploader from 'components/inputs/ImageUploader/ImageUploader'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}))

const ProfileForm = ({ push }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} component={Form} className={classes.root}>
      <Grid item xs={12} sm={12}>
        <Field label='username' name='displayName' />
      </Grid>

      <Grid item xs={12}>
        <Field name='photoURL' component={ImageUploader} />
      </Grid>

      <Grid item xs={12} container justify='flex-end'>
        <Button
          variant='contained'
          color='primary'
          type='submit'
        >
          Confirm
        </Button>

        <Button onClick={() => push('/profile')}>Cancel</Button>
      </Grid>
    </Grid>
  )
}

export default ProfileForm
