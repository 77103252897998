import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { app } from 'utils/firebase'
import { syncCollection, update, set, create, destory } from 'actions/resources'
import { getCollection } from 'selectors/resources'

const db = app.firestore()

// export const useResource = (path, schema, id) => {
//   const dispatch = useDispatch()
//   const resource = useSelector(state => getResource(state, schema, id)) || []
//   const [loading, setLoading] = useState(true)

//   useEffect(() => {
//     return db.doc(path).onSnapshot(snapshot => {
//       const doc = ({ id: snapshot.id, ...snapshot.data() })

//       dispatch(
//         syncResource(doc, schema, id)
//       )
//       setLoading(false)
//     })
//   }, [path, schema, id, dispatch])

//   return [resource, { loading }]
// }

export const useResource = (path) => {
  const [document, setDocument] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    const unsubscribe = db.doc(path)
      .onSnapshot(queryDocSnapshot => {
        const data = queryDocSnapshot.data()

        if (data) {
          setDocument(data)
        }

        setLoading(false)
      })

    return unsubscribe
  }, [path])

  return [document, { loading }]
}

export const useCollection = (path, schema, buildQuery = query => query, queryDeps = []) => {
  const [ids, setIds] = useState([])
  const dispatch = useDispatch()
  const collection = useSelector(state => getCollection(state, schema, ids)) || []
  const memoizedBuildQuery = useCallback(buildQuery, queryDeps)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    return memoizedBuildQuery(db.collection(path)).onSnapshot(snapshot => {
      const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))

      dispatch(
        syncCollection(docs, schema)
      )

      setIds(docs.map(doc => doc.id))
      setLoading(false)
    })
  }, [path, schema, dispatch, memoizedBuildQuery])

  return [collection, { loading }]
}

export const useResourceAction = (schema) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const actionResource = (thunkAction) => {
    const action = (...params) => {
      setLoading(true)

      console.log(params)
      return dispatch(thunkAction(...params, schema, {}))
        .then(response => {
          setLoading(false)

          return response
        })
        .catch(error => {
          setLoading(false)
          setError(error)

          throw error
        })
    }

    return [action, loading, error]
  }

  const [updateResource, updating, errorFromUpdating] = actionResource(update)
  const [setResource, setting, errorFromSetting] = actionResource(set)
  const [createResource, creating, errorFromCreating] = actionResource(create)
  const [destoryResource, destroying, erroeFromDestroying] = actionResource(destory)

  return {
    update: updateResource,
    set: setResource,
    create: createResource,
    destory: destoryResource,

    updating,
    setting,
    creating,
    destroying,

    errorFromUpdating,
    errorFromSetting,
    errorFromCreating,
    erroeFromDestroying
  }
}
