import { hot } from 'react-hot-loader/root'
import React from 'react'

import { Provider } from 'react-redux'
import store from 'utils/store'

import Router from './Router'
import { AuthProvider } from 'contexts/AuthContext'

function App () {
  return (
    <AuthProvider>
      <Provider store={store}>
        <Router />
      </Provider>
    </AuthProvider>
  )
}

export default hot(App)
