import React, { useState, useRef } from 'react'
import { CircularProgress, Card, CardActionArea, Typography, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { uuid } from 'uuidv4'
import { startCase } from 'lodash'
import { app } from 'utils/firebase'

import { useAuth } from 'contexts/AuthContext'

import useStyles from './ImageUploaderStyles'

const storage = app.storage()

const ImageUploader = ({ name, value, onChange }) => {
  const {
    uploaderContainer, inputFile, imageContainer,
    loaderContainer, removeButton, placeholderText
  } = useStyles()
  const { setError } = useAuth()

  const inputRef = useRef()
  const imageRef = useRef()

  const [percentUpload, setPercentUpload] = useState(0)
  const [uploading, setUploading] = useState(false)

  const handleClick = () => {
    !value && inputRef.current.click()
  }

  const handleChange = (e) => {
    const imageFile = e.target.files[0]

    if (imageFile) {
      setUploading(true)

      imageRef.current = storage.ref(`images/${uuid()}_${imageFile.name}`)
      const task = imageRef.current.put(imageFile)

      task.on(
        'state_changed',
        (snapshot) => {
          const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

          setPercentUpload(percent)
        },
        (error) => {
          setError(error)
          setUploading(false)
        },
        () => {
          imageRef.current.getDownloadURL().then(url => {
            onChange && onChange(url)

            setError('')

            setUploading(false)
          })
        }
      )
    }
  }

  const handleRemove = (e) => {
    e.stopPropagation()
    onChange && onChange('')
  }

  return (
    <>
      <Typography
        variant='caption'
        style={{
          opacity: value ? 0.8 : 0,
          marginLeft: 14
        }}
      >
        {startCase(name)}
      </Typography>

      <Card>
        <CardActionArea
          className={uploaderContainer}
          onClick={handleClick}
          disabled={uploading}
        >
          <input
            type='file'
            ref={inputRef}
            onChange={handleChange}
            className={inputFile}
          />

          <div className={imageContainer}>
            {
              value && (<img src={value} alt='uploaded_image' />)
            }
          </div>

          {
            uploading && (
              <div className={loaderContainer}>
                <CircularProgress value={percentUpload} variant='static' size={60} />
                <Typography variant='overline' color='primary' display='block'>Uploading...</Typography>
              </div>
            )
          }

          {
            value && (
              <IconButton className={removeButton} onClick={handleRemove}>
                <Close />
              </IconButton>
            )
          }

          {
            !value && !uploading && (
              <Typography className={placeholderText}>
                Click or Tap To Upload Image for "{startCase(name)}"
              </Typography>
            )
          }
        </CardActionArea>
      </Card>
    </>
  )
}

export default ImageUploader
