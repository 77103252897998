import React from 'react'
import { useParams } from 'react-router-dom'
import CircularLoader from 'components/CircularLoader'
import { makeStyles } from '@material-ui/core/styles'

import { useResource } from 'hooks/resources'
import { projectSchema } from 'schemas'

import * as Showdown from 'showdown'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
})

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}))

function ProjectOverviewPage () {
  const { id } = useParams()
  const [project, { loading }] = useResource(`projects/${id}`, projectSchema, id)
  const htmlString = converter.makeHtml(project.overview)
  const classes = useStyles()

  return (
    <div>
      {
        loading ? <CircularLoader />
          : <div className={classes.root} dangerouslySetInnerHTML={{ __html: htmlString || '<p>&nbsp;</p>' }} />

      }
    </div>
  )
}

export default ProjectOverviewPage
