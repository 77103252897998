import React, { Component } from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { Button } from '@material-ui/core'
import { FieldArray, connect, getIn } from 'formik'

const SortableList = SortableContainer(({ children, ...rest }) => (
  <div>
    {
      React.Children.map(children, child => (
        React.cloneElement(child, { ...rest })
      ))
    }
  </div>
))

export class SortableListContainer extends Component {
  static defaultProps = {
    noLabel: false
  }

  renderField (arrayHelpers, index) {
    const {
      name, formik, render, component: Component,
      t, tReady, i18nOptions, defaultNS, reportNS,
      ...rest
    } = this.props

    const helperBag = {
      name: `${name}[${index}]`,
      index,
      collection: name,
      onRemove: (e, { value }) => {
        value.hasOwnProperty('id') ? (
          formik.setFieldValue(`${name}[${index}]._destroy`, !value._destroy)
        ) : (
          arrayHelpers.remove(index)
        )
      }
    }

    if (render) {
      return render(helperBag, { arrayHelpers, index })
    }
    else if (Component) {
      return <Component {...helperBag} {...rest} key={helperBag.name} />
    }
  }

  render () {
    const { name, label, noLabel, newItemText, formik } = this.props

    const fieldArrayAttributes = getIn(formik.values, name) || []

    return (
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <>
            {!noLabel && <label>{label}</label>}

            <SortableList
              useDragHandle
              onSortEnd={({ oldIndex, newIndex, collection }) => {
                arrayHelpers.move(oldIndex, newIndex)
              }}
            >
              {
                fieldArrayAttributes.map((lineItem, index) => this.renderField(arrayHelpers, index))
              }
            </SortableList>

            <Button
              variant='contained'
              onClick={() => arrayHelpers.push({})}
            >
              {newItemText}
            </Button>
          </>
        )}
      />
    )
  }
}

export default connect(SortableListContainer)
