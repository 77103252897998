import React, { useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link, Redirect, useHistory } from 'react-router-dom'
import {
  AppBar, Toolbar, Menu, MenuItem, IconButton, Button, Typography
  // Drawer, List, ListItem, ListItemText, Divider
} from '@material-ui/core'
import {
  Menu as MenuIcon,
  // ChevronLeft as ChevronLeftIcon,
  AccountCircle as AccountCircleIcon
} from '@material-ui/icons'

// import { useAuth } from 'contexts/AuthContext'
import { app } from 'utils/firebase'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  title: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}))

// const db = app.firestore()
const auth = app.auth()

function TopNavigation ({ children }) {
  const history = useHistory()
  // const { currentUser } = useAuth()

  const classes = useStyles()
  const [navigationOpen, setNavigationOpen] = useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuOpen = Boolean(anchorEl)
  // const [projects, setProjects] = useState([])

  // useEffect(() => {
  //   if (currentUser) {
  //     return db.collection('projects').where('permissions', 'array-contains', currentUser.uid).onSnapshot(snapshot => {
  //       setProjects(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
  //     })
  //   }
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const authenticated = true

  function handleNavigationOpen () {
    setNavigationOpen(true)
  }

  // function handleNavigationClose () {
  //   setNavigationOpen(false)
  // }

  function handleMenu (event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMenuClose () {
    setAnchorEl(null)
  }

  function handleSelectAccount () {
    setAnchorEl(null)
    history.push('/profile')
  }

  function handleSignOut () {
    auth.signOut().then(() => {
      return <Redirect to='/sign_in' />
    }).catch(error => {
      alert(error)
    }
    )
  }

  return (
    <div className={classes.root}>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: navigationOpen
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleNavigationOpen}
            edge='start'
            className={clsx(classes.menuButton, navigationOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant='h6' noWrap className={classes.title}>
            Documentation
          </Typography>

          <Button color='inherit' component={Link} to='/'>Home</Button>

          {
            authenticated ? (
              <div>
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleMenu}
                  color='inherit'
                >
                  <AccountCircleIcon />
                </IconButton>

                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={menuOpen}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleSelectAccount}>Account</MenuItem>
                  <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                </Menu>
              </div>
            ) : (
              <Button color='inherit'>Sign In</Button>
            )
          }
        </Toolbar>
      </AppBar>

      {/* <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={navigationOpen}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleNavigationClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />

        <List>
          <ListItem button component={Link} to='/projects' key='/projects'>
            <ListItemText primary='View All Projects' />
          </ListItem>
        </List>

        <Divider />

        <List>
          {
            projects.map(project => (
              <ListItem
                button
                component={Link}
                to={`/projects/${project.id}`}
                key={`/projects/${project.id}`}
              >
                <ListItemText primary={project.name} />
              </ListItem>
            ))
          }
        </List>
      </Drawer> */}

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: navigationOpen
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  )
}

export default TopNavigation
