import React from 'react'
import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core'
import { Delete, Add } from '@material-ui/icons'
import FieldObject from 'fields/FieldObject'
import { makeStyles } from '@material-ui/core/styles'
import PropertieField from 'modules/projects/fields/PropertieField'

import { connect, getIn } from 'formik'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  }
}))

export function SchemasField ({ name, formik }) {
  const classes = useStyles()
  const values = getIn(formik.values, name) || {}

  function handlDelete (key) {
    delete values[key]

    formik.setFieldValue(name, values)
  }

  function handleAdd () {
    const objectLength = values ? Object.getOwnPropertyNames(values).length + 1 : 1

    values[`new${objectLength}`] = {
      type: 'object',
      properties: {}
    }

    formik.setFieldValue(name, values)
  }

  return (
    <div>
      {
        values && Object.keys(values).map((key, id) =>
          <Card key={id} className={classes.root}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FieldObject name={name} label='Name' placeholder='Name' value={key} />
                </Grid>
              </Grid>

              <PropertieField name={`${name}.${key}`} />
            </CardContent>

            <CardActions>
              <Button onClick={() => handlDelete(key)}>
                <Delete />
                Remove
              </Button>
            </CardActions>
          </Card>
        )
      }
      <Button onClick={() => handleAdd()}>
        <Add />
        Add
      </Button>
    </div>
  )
}

export default connect(SchemasField)
