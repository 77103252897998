import React from 'react'

import { connect, getIn } from 'formik'
import { Tabs, TabPane } from 'components/Tabs'
import ExpansionPanelList from '../ExpansionPanelList'

export function OperationsField ({ name, formik, ...rest }) {
  const values = getIn(formik.values, name)

  return (
    <Tabs
      contained
      scrollButtons='off'
    >
      <TabPane label='GET'>
        {values.get ? <ExpansionPanelList /> : 'NOT FOUND'}
      </TabPane>

      <TabPane label='POST'>
        {values.post ? 'FOUND' : 'NOT FOUND'}
      </TabPane>

      <TabPane label='PUT'>
        {values.put ? 'FOUND' : 'NOT FOUND'}
      </TabPane>

      <TabPane label='PATCH'>
        {values.patch ? 'FOUND' : 'NOT FOUND'}
      </TabPane>

      <TabPane label='DELETE'>
        {values.delete ? 'FOUND' : 'NOT FOUND'}
      </TabPane>
    </Tabs>
  )
}

export default connect(OperationsField)
