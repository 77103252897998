import React from 'react'
import { Link } from 'react-router-dom'

import ProfileForm from 'forms/ProfileForm'
import { PageHeader } from 'components/navigation/PageHeader'
import CircularLoader from 'components/CircularLoader'
import { useAuth } from 'contexts/AuthContext'
import { useResourceAction, useResource } from 'hooks/resources'
import userSchema from 'schemas/userSchema'
import { IconButton } from '@material-ui/core'
import {
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons'

function ProjectEditPage ({ history }) {
  const { currentUser } = useAuth()
  const { update } = useResourceAction(userSchema)
  const [user, { loading }] = useResource(`users/${currentUser.uid}`, userSchema, currentUser.uid)

  return (
    <div className='page'>
      <PageHeader
        title={`Editing ${user.displayName}`}
        top={64}
        leftIconButton={
          <IconButton component={Link} to='/projects'>
            <ChevronLeftIcon />
          </IconButton>
        }
      />

      {
        loading ? <CircularLoader />
          : <ProfileForm id={currentUser.uid} user={user} push={history.push} onSubmit={update} />
      }
    </div>
  )
}

export default ProjectEditPage
