import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { Delete, Add } from '@material-ui/icons'
import FieldObject from 'fields/FieldObject'
import { makeStyles } from '@material-ui/core/styles'
import CardCollapse from 'components/CardCollapse'
import SortableFieldArray from 'fields/SortableFieldArray'
import ServerField from 'modules/projects/fields/ServerField'
import OperationsField from 'modules/projects/fields/OperationsField'

import { connect, getIn } from 'formik'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  }
}))

export function PathField ({ name, formik }) {
  const classes = useStyles()
  const values = getIn(formik.values, name) || {}

  function handlDelete (key) {
    delete values[key]

    formik.setFieldValue(name, values)
  }

  function handleAdd () {
    const objectLength = values ? Object.getOwnPropertyNames(values).length + 1 : 1

    values[`new${objectLength}`] = {
      type: 'object',
      properties: {}
    }

    formik.setFieldValue(name, values)
  }

  return (
    <div>
      {
        values && Object.keys(values).map((key, id) =>
          <CardCollapse title={key} key={id} className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FieldObject name={name} label='Name' placeholder='Name' value={key} />
              </Grid>

              <Grid item xs={12}>
                <CardCollapse title='Servers'>
                  <SortableFieldArray
                    name={`${name}.${key}.servers`}
                    component={ServerField}
                    newItemText='Add new server'
                    noLabel
                  />
                </CardCollapse>
              </Grid>

              <Grid item xs={12}>
                <CardCollapse title='Operations'>
                  <OperationsField name={`${name}.${key}`} />
                </CardCollapse>
              </Grid>
            </Grid>

            <Button onClick={() => handlDelete(key)}>
              <Delete />
              Remove
            </Button>
          </CardCollapse>
        )
      }
      <Button onClick={() => handleAdd()}>
        <Add />
        Add
      </Button>
    </div>
  )
}

export default connect(PathField)
