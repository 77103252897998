import React from 'react'
import { Form } from 'formik'
import { Grid, Button } from '@material-ui/core'

import Field from 'fields/Field'

const SignUpForm = () => {
  return (
    <Grid container spacing={3} direction='column' component={Form}>
      <Grid item xs={12}>
        <Field
          name='email'
          noLabel
          type='email'
          variant='standard'
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          name='password'
          noLabel
          type='password'
          variant='standard'
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          name='username'
          noLabel
          variant='standard'
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          fullWidth
        >
          Sign Up
        </Button>
      </Grid>
    </Grid>
  )
}

export default SignUpForm
