import React, { useState } from 'react'
import { Card, CardContent, Button, Collapse, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import classNames from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },

  header: {
    padding: 0
  },

  title: {
    padding: '0 !important',
    display: 'flex',
    alignItems: 'center'
  },

  clickableArea: {
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  },

  titleContent: {
    justifyContent: 'space-between',
    textAlign: 'left',
    textTransform: 'none'
  },

  titleText: {
    wordBreak: 'break-word'
  },

  caret: {
    margin: theme.spacing(2)
  },

  expanded: {
    transform: 'rotate(180deg)'
  }
}))

export function CardCollapse ({ title, children }) {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent classes={{ root: classes.title }}>
        <Button
          onClick={() => setExpanded(!expanded)}
          fullWidth
          classes={{
            root: classes.clickableArea,
            label: classes.titleContent
          }}
        >
          <Typography variant='h6' className={classes.titleText}>{title}</Typography>

          <ExpandMore
            className={classNames(classes.caret, {
              [classes.expanded]: expanded
            })}
          />
        </Button>
      </CardContent>

      <Collapse in={expanded} unmountOnExit>
        <CardContent>
          {children}
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default CardCollapse
