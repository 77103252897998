import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'

import { JsonEditor as Editor } from 'jsoneditor-react'
import ace from 'brace'
import 'jsoneditor-react/es/editor.min.css'
import 'brace/mode/json'
import 'brace/theme/github'

const modes = ['tree', 'form', 'view', 'code', 'text']

const useStyles = makeStyles(() => ({
  editor: {
    height: '60vh',

    '& div.jsoneditor': {
      height: '60vh'
    }
  }
}))

export function EditorField ({ name, ...rest }) {
  const classes = useStyles()

  return (
    <Field
      name={name}
      render={({ field, form: { setFieldValue } }) => (
        <>
          <div className={classes.editor}>
            <Editor
              {...field}
              ace={ace}
              theme='ace/theme/github'
              allowedModes={modes}
              onChange={data => setFieldValue(name, data)}
              {...rest}
            />
          </div>
        </>
      )}
    />
  )
}

export default EditorField
