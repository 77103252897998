import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import TopNavigation from './components/surfaces/TopNavigation'
import PrivateRoute from 'components/PrivateRoute'

// import HomePage from './pages'
import ProjectsPage from './pages/projects'
import ProjectNewPage from './pages/projects/new'
import ProjectPage from './pages/projects/[id]'
import ProjectEditPage from './pages/projects/[id]/edit'
import ProjectPermissionPage from './pages/projects/[id]/permissions'

import ProfilePage from './pages/profile'
import ProfileEditPage from './pages/profile/edit'

import SignInPage from './pages/SignInPage'
import SignUpPage from './pages/SignUpPage'

function AppRouter () {
  return (
    <Router>
      <Switch>
        <Route path='/sign_in' exact component={SignInPage} />
        <Route path='/sign_up' exact component={SignUpPage} />

        <TopNavigation>
          <Switch>
            <PrivateRoute path='/' exact component={ProjectsPage} />
            <PrivateRoute path='/projects' exact component={ProjectsPage} />
            <PrivateRoute path='/projects/new' component={ProjectNewPage} />
            <PrivateRoute path='/projects/:id/edit' component={ProjectEditPage} />
            <PrivateRoute path='/projects/:id/permissions' component={ProjectPermissionPage} />
            <PrivateRoute path='/projects/:id' component={ProjectPage} />

            <PrivateRoute path='/profile' exact component={ProfilePage} />
            <PrivateRoute path='/profile/edit' component={ProfileEditPage} />
          </Switch>
        </TopNavigation>
      </Switch>
    </Router>
  )
}

export default AppRouter
