import React from 'react'
import { SettingsRounded, GroupAddRounded, Delete } from '@material-ui/icons'
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'

import { useResource, useResourceAction } from 'hooks/resources'
import { projectSchema } from 'schemas'

import { PageHeader, NavItem } from 'components/navigation/PageHeader'
import { useAuth } from 'contexts/AuthContext'

import ProjectReferencePage from './reference'
import ProjectGuidePage from './guide'
import ProjectExamplePage from './example'
import ProjectOverviewPage from './overview'

function ProjectPage ({ history }) {
  const { id } = useParams()
  const { currentUser } = useAuth()
  const { destory } = useResourceAction(projectSchema)

  const [project] = useResource(`projects/${id}`)
  const { path, url } = useRouteMatch()

  function handleDelete () {
    const confirmDelete = window.confirm('Confirm Delete ?')

    if (confirmDelete) {
      destory(id, 'projects')
        .then(history.push('/projects'))
    }
  }

  function primaryActions () {
    if (project.editors && project.editors.includes(currentUser.uid)) {
      return [
        {
          avatar: <SettingsRounded />,
          content: 'Edit',
          to: `${url}/edit`
        }
      ]
    }
    if (project.owners && project.owners.includes(currentUser.uid)) {
      return [
        {
          avatar: <SettingsRounded />,
          content: 'Edit',
          to: `${url}/edit`
        },
        {
          avatar: <GroupAddRounded />,
          content: 'Users and permission',
          to: `${url}/permissions`
        },
        {
          avatar: <Delete />,
          content: 'Delete',
          onClick: handleDelete
        }
      ]
    }
    else {
      return null
    }
  }

  return (
    <div className='page'>
      <PageHeader title={project.name} top={64} actions={primaryActions()}>
        <NavItem label='Overview' to={`${url}`} />
        <NavItem label='Guides' to={`${url}/guides`} />
        <NavItem label='Reference' to={`${url}/reference`} />
        <NavItem label='Examples' to={`${url}/examples`} />
      </PageHeader>

      <div className='content'>
        <Switch>
          <Route path={`${path}`} component={ProjectOverviewPage} exact />
          <Route path={`${path}/guides`} component={ProjectGuidePage} />
          <Route path={`${path}/reference`} component={ProjectReferencePage} />
          <Route path={`${path}/examples`} component={ProjectExamplePage} />
        </Switch>
      </div>
    </div>
  )
}

export default ProjectPage
