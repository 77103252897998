import React from 'react'
import { SettingsRounded } from '@material-ui/icons'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { PageHeader, NavItem } from 'components/navigation/PageHeader'

import ProfileOverviewPage from './overview'

function ProjectPage () {
  const { path, url } = useRouteMatch()

  function primaryActions () {
    return [
      {
        avatar: <SettingsRounded />,
        content: 'Edit',
        to: `${url}/edit`
      }
    ]
  }

  return (
    <div className='page'>
      <PageHeader title='Profile' top={64} actions={primaryActions()}>
        <NavItem label='Overview' to={`${url}`} />
      </PageHeader>

      <div className='content'>
        <Switch>
          <Route path={`${path}`} component={ProfileOverviewPage} exact />
        </Switch>
      </div>
    </div>
  )
}

export default ProjectPage
