import {
  SYNC_RESOURCE,
  SYNC_COLLECTION,
  FETCH_RESOURCE_SUCCEEDED
} from 'constants/resources'

import { app } from 'utils/firebase'

const db = app.firestore()

export const syncResource = (doc, schema, id) => ({
  type: SYNC_RESOURCE,
  payload: {
    doc,
    schema,
    id
  }
})

export const syncCollection = (docs, schema) => ({
  type: SYNC_COLLECTION,
  payload: {
    docs,
    schema
  }
})

export const fetchResourceSucceeded = (snapshot, schema, options) => ({
  type: FETCH_RESOURCE_SUCCEEDED,
  snapshot,
  schema,
  options
})

export const update = (id, path, data, schema, options = {}) => (dispatch) => {
  return db.collection(path)
    .doc(id)
    .update(data)
    .then(response => {
      return response
    })
}

export const set = (id, path, data, schema, options = {}) => (dispatch) => {
  return db.collection(path)
    .doc(id)
    .set(data)
    .then(response => {
      return response
    })
}

export const create = (path, data, schema, options = {}) => (dispatch) => {
  return db.collection(path)
    .add(data)
    .then(response => {
      return response
    })
}

export const destory = (id, path, data, schema, options = {}) => (dispatch) => {
  return db.collection(path)
    .doc(id)
    .delete()
    .then(response => {
      return response
    })
}
