import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Hidden, Drawer, List, ListItem, Menu, IconButton } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

import styles from './ResponsiveMenuStyles'

export function ResponsiveMenu ({
  component: Component = IconButton, content = <MoreVert />, children, onClose, drawerAnchor, classes, ...rest
}) {
  const [anchor, setAnchor] = useState(null)

  function handleOpen (e) {
    setAnchor(e.currentTarget)
  }

  function handleClose () {
    onClose && onClose()
    setAnchor(null)
  }

  function onClickChild (e, { onClick }) {
    onClick && onClick(e)
    setAnchor(null)
  }

  return (
    <>
      <Component onClick={handleOpen} {...rest}>
        {content}
      </Component>

      <Hidden smUp>
        <Drawer
          anchor={drawerAnchor}
          open={!!anchor}
          onClose={handleClose}
        >
          <List>
            {
              React.Children.map(React.Children.toArray(children), child => (
                React.cloneElement(
                  child,
                  {
                    component: ListItem,
                    classes: { root: classes.listItem },
                    ...child.props,
                    onClick: (e) => onClickChild(e, child.props)
                  }
                )
              ))
            }
          </List>
        </Drawer>
      </Hidden>

      <Hidden xsDown>
        <Menu
          anchorEl={anchor}
          open={!!anchor}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {children}
        </Menu>
      </Hidden>
    </>
  )
}

ResponsiveMenu.propTypes = {
  component: PropTypes.func,
  content: PropTypes.node,
  trigger: PropTypes.node,
  children: PropTypes.node,
  drawerAnchor: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.object
}

ResponsiveMenu.defaultProps = {
  drawerAnchor: 'bottom'
}

export default withStyles(styles)(ResponsiveMenu)
