import { withFormik } from 'formik'
import moment from 'moment'

import ProjectForm from './ProjectForm'

const formikConfig = {
  mapPropsToValues: ({ project, uid }) => ({
    json_data: project ? project.json_data || {} : {},
    name: project ? project.name || '' : '',
    overview: project ? project.overview || '' : '',
    guide: project ? project.guide || '' : '',
    example: project ? project.example || '' : '',
    owners: project ? project.owners || [] : [],
    editors: project ? project.editors || [] : [],
    viewers: project ? project.viewers || [] : [],
    permissions: project ? project.owners || [] : []
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    let json_data = JSON.parse(JSON.stringify(values.json_data))
    // const components = json_data.components

    console.log(values)
    // if (json_data.paths) {
    //   json_data = {
    //     ...json_data,
    //     components: {
    //       ...components,
    //       securitySchemes: {
    //         basic_auth: {
    //           type: 'http',
    //           scheme: 'basic'
    //         },
    //         bearer_auth: {
    //           type: 'http',
    //           scheme: 'bearer'
    //         }
    //       }
    //     },
    //     securityDefinitions: {
    //       basic_auth: {
    //         type: 'http',
    //         scheme: 'basic'
    //       },
    //       bearer_auth: {
    //         type: 'http',
    //         scheme: 'bearer'
    //       }
    //     }
    //   }

    //   Object.values(json_data.paths).forEach(pathObj => {
    //     ['get', 'put', 'post', 'delete', 'patch'].forEach(method => {
    //       if (pathObj[method]) {
    //         const temp = pathObj[method]
    //         if (pathObj[method]['tags'][0] === 'Authentication') {
    //           pathObj[method] = { ...temp, responses: {}, security: [{ basic_auth: [] }] }
    //         }
    //         else {
    //           pathObj[method] = { ...temp, responses: {}, security: [{ bearer_auth: [] }] }
    //         }

    //         if (pathObj[method]['parameters']) {
    //           pathObj[method]['parameters'] = pathObj[method]['parameters'].filter((data) => data.name !== 'X-Access-Token')
    //         }
    //       }
    //     })
    //   })
    // }

    const newValues = { ...values, json_data, updated_at: moment().toISOString(true) }

    props.isCreate ? props.onSubmit('projects', { ...newValues, created_at: moment().toISOString(true) })
      .then(responses => {
        setSubmitting(false)
        props.afterSubmit(responses.id)
        props.push(`/projects/${responses.id}`)
      }) : props.onSubmit(props.id, 'projects', newValues)
      .then(() => {
        setSubmitting(false)
        props.push(`/projects/${props.id}`)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export default withFormik(formikConfig)(ProjectForm)
