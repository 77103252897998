import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SignUpForm from 'forms/SignUpForm'
import Toast from 'components/Toast'
import { app } from 'utils/firebase'
import { Link } from 'react-router-dom'

const auth = app.auth()
const db = app.firestore()

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  pageContainer: {
    height: '100vh',
    backgroundSize: 'cover',
    backdropFilter: 'blur(6px)',
    backgroundColor: '#f9f9f9'
  },

  innerContainer: {
    border: '1px solid #d8dee2',
    padding: '30px !important',
    height: '100%',

    [breakpoints.up('sm')]: {
      borderRadius: 12,
      height: 'unset',

      '& > form': {
        width: '80%',
        margin: '0 auto'
      }
    }
  },

  headerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > p': {
      fontSize: '2rem',
      fontWeight: 500,
      marginLeft: 8
    },

    marginTop: 70,
    marginBottom: 24,

    [breakpoints.up('sm')]: {
      marginTop: 0
    }
  },

  newUserSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > a': {
      marginLeft: 4,
      color: '#4183c4',
      textDecoration: 'none'
    }
  }
}))

const SignUpPage = ({ history }) => {
  const classes = useStyles()

  const [error, setError] = useState(null)

  const handleLogin = ({ email, password, username }) => {
    return auth.createUserWithEmailAndPassword(email, password)
      .then(({ user }) =>
        db.collection('users').doc(user.uid).set({
          uid: user.uid,
          email: user.email,
          displayName: username,
          provider: user.providerData[0].providerId
        })
      )
      .catch(error => {
        setError(error.message)

        throw error
      })
  }

  auth.onAuthStateChanged(async (user) => {
    if (user) {
      history.push('/')
    }
  })

  return (
    <Grid
      className={classes.pageContainer}
      container
      justify='center'
      alignItems='center'
    >
      <Grid item xs={12} sm={4} className={classes.innerContainer}>
        <div className={classes.headerSection}>
          <p>Create your account</p>
        </div>

        <SignUpForm onSubmit={handleLogin} />

        <p className={classes.newUserSection}>Already have an account?<Link to='/sign_in'> Sign in</Link></p>
        <Toast
          message={error}
          onClear={() => setError('')}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          autoHideDuration={10000}
        />
      </Grid>
    </Grid>
  )
}

export default SignUpPage
