import React from 'react'
import { Grid } from '@material-ui/core'
import SortableFormSection from 'fields/SortableFieldArray/SortableFormSection'
import { connect, getIn } from 'formik'

import Field from 'fields/Field'

export function ServerField ({ name, formik, ...rest }) {
  const values = getIn(formik.values, name)

  return (
    <SortableFormSection title={values.url} {...rest}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field label='Url' name={`${name}.url`} />
        </Grid>

        <Grid item xs={12}>
          <Field label='Description' name={`${name}.description`} />
        </Grid>
      </Grid>
    </SortableFormSection>
  )
}

export default connect(ServerField)
