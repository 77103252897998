import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  uploaderContainer: {
    height: 200,
    backgroundColor: palette.grey[100],
    position: 'relative'
  },

  inputFile: {
    display: 'none'
  },

  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',

    '& > img': {
      maxHeight: '100%',
      maxWidth: '100%'
    }
  },

  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)'
  },

  removeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },

  placeholderText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)'
  }
}))
