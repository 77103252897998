import * as firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBIwRc8l2qm0_8tFMTGUJX0O1uOQX2AnCg',
  authDomain: 'swagger-docs.firebaseapp.com',
  databaseURL: 'https://swagger-docs.firebaseio.com',
  projectId: 'swagger-docs',
  storageBucket: 'swagger-docs.appspot.com',
  messagingSenderId: '348014465934',
  appId: '1:348014465934:web:85599c6a1eedce52'
}

export const app = !firebase.apps.length ? (
  firebase.initializeApp(firebaseConfig)
) : (
  firebase.app()
)

export {
  firebase
}
