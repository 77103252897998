import React from 'react'
import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core'
import { Delete, Add } from '@material-ui/icons'
import FieldObject from 'fields/FieldObject'
import { makeStyles } from '@material-ui/core/styles'
import CardCollapse from 'components/CardCollapse'
import { connect, getIn } from 'formik'
import Field from 'fields/Field'
import SelectInput from 'components/inputs/SelectInput'
import arrayToOptions from 'utils/arrayToOptions'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  }
}))

export function PropertieField ({ name, formik }) {
  const classes = useStyles()
  const values = getIn(formik.values, name) || {}

  function handlDelete (key) {
    delete values[key]

    formik.setFieldValue(name, values)
  }

  function handleAdd () {
    const objectLength = values ? Object.getOwnPropertyNames(values).length + 1 : 1

    values[`new${objectLength}`] = {
      type: '',
      format: ''
    }

    formik.setFieldValue(name, values)
  }

  return (
    <div className={classes.root}>
      <CardCollapse title='Properties'>
        {
          values && Object.keys(values.properties).map((key, id) =>
            <Card key={id} className={classes.root}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FieldObject name={name} label='Name' placeholder='Name' value={key} />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      name={`${name}.properties.${key}.type`}
                      label='Type'
                      variant='standard'
                      component={SelectInput}
                      isClearable={false}
                      options={arrayToOptions(['string', 'number', 'integer', 'boolean', 'array', 'object'])}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field label='Format' name={`${name}.properties.${key}.format`} />
                  </Grid>
                </Grid>

              </CardContent>

              <CardActions>
                <Button onClick={() => handlDelete(key)}>
                  <Delete />
                  Remove
                </Button>
              </CardActions>
            </Card>
          )
        }
        <Button onClick={() => handleAdd()}>
          <Add />
          Add
        </Button>
      </CardCollapse>
    </div>
  )
}

export default connect(PropertieField)
