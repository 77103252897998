import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table, TableHead, TableBody, TableRow, TableCell,
  Paper, Grid
} from '@material-ui/core'
import { Button } from 'components/buttons'

import TimeDifferenceFormatter from 'components/formatters/TimeDifferenceFormatter'
import { useCollection } from 'hooks/resources'
import { projectSchema } from 'schemas'
import { useAuth } from 'contexts/AuthContext'

const useStyles = makeStyles(theme => ({
  page: {
    padding: theme.spacing(2)
  },
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  button: {
    margin: theme.spacing(1)
  },
  link: {
    color: '#4183c4',
    textDecoration: 'none'
  }
}))

function ProjectsPage () {
  const { currentUser } = useAuth()
  const classes = useStyles()
  const [projects] = useCollection('projects', projectSchema, filter => {
    return filter.where('permissions', 'array-contains', currentUser.uid).orderBy('updated_at', 'desc')
  })

  return (
    <div className={classes.page}>
      <Grid item xs={12} container justify='flex-end'>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          component={Link}
          to='/projects/new'
        >
          Add NEW PROJECT
        </Button>
      </Grid>
      <Paper className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>

              <TableCell align='right'>
                Last update
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              projects.map(project => (
                project && (
                  <TableRow key={project.id}>
                    <TableCell component='th' scope='row'>
                      <Link to={`/projects/${project.id}`} className={classes.link}>{project.name}</Link>
                    </TableCell>

                    <TableCell align='right'>
                      <TimeDifferenceFormatter value={project.updated_at} />
                    </TableCell>
                  </TableRow>
                )
              ))
            }
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

export default ProjectsPage
