import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table, TableHead, TableBody, TableRow, TableCell,
  Paper, Grid, IconButton
} from '@material-ui/core'
import { Delete, Update, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons'

import { PageHeader } from 'components/navigation/PageHeader'
import { Button } from 'components/buttons'
import Modal from 'components/Modal'
import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'
import PermissionForm from 'forms/PermissionForm'
import { useResource, useResourceAction } from 'hooks/resources'
import { projectSchema } from 'schemas'
import { app } from 'utils/firebase'

const useStyles = makeStyles(theme => ({
  page: {
    padding: theme.spacing(2)
  },
  root: {
    marginTop: 16,
    width: '100%',
    overflowX: 'auto'
  },
  button: {
    margin: theme.spacing(1)
  },
  link: {
    color: '#4183c4',
    textDecoration: 'none'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'aliceblue'
    }
  }
}))

const db = app.firestore()

function ProjectPermissionPage ({ history }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [user, setUser] = useState()

  const classes = useStyles()
  const { id } = useParams()
  const { set, destory } = useResourceAction(projectSchema)
  const [project] = useResource(`projects/${id}`, projectSchema, id)
  const [users, setUsers] = useState([])

  useEffect(() => {
    if (id) {
      return db.collection(`projects/${id}/permissions`).onSnapshot(snapshot => {
        setUsers(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleCreate () {
    setModalOpen(true)
    setIsUpdate(false)
    setUser(false)
  }

  function handleUpdate (user) {
    setModalOpen(true)
    setIsUpdate(true)
    setUser(user)
  }

  function handleDelete (userId) {
    const confirmDelete = window.confirm('Confirm Delete ?')

    if (confirmDelete) {
      destory(userId, `projects/${id}/permissions`)
    }
  }

  return (
    <div>
      <PageHeader
        title={`Users and permissions [${project.name}]`}
        top={64}
        leftIconButton={
          <IconButton component={Link} to={`/projects/${id}`}>
            <ChevronLeftIcon />
          </IconButton>
        }
      />

      <div className={classes.page}>
        <Grid item xs={12} container justify='flex-end'>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={() => handleCreate()}
          >
            Add new users
          </Button>
        </Grid>

        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>

                <TableCell align='right'>
                  Role
                </TableCell>

                <TableCell align='right'>
                  Settings
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                users.map((user) => (
                  <TableRow className={classes.tableRow} key={user.id}>
                    <TableCell component='th' scope='row'>
                      {user.email}
                    </TableCell>

                    <TableCell align='right'>
                      {user.role}
                    </TableCell>

                    <TableCell align='right'>
                      <ResponsiveMenu>
                        <ResponsiveMenuItem
                          avatar={<Update />}
                          content='Update'
                          key='Update'
                          onClick={() => handleUpdate(user)}
                        />

                        <ResponsiveMenuItem
                          avatar={<Delete />}
                          content='Delete'
                          key='Delete'
                          onClick={() => handleDelete(user.id)}
                        />
                      </ResponsiveMenu>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Paper>
      </div>

      <Modal
        isOpen={modalOpen}
        onCloseModal={() => setModalOpen(false)}
        title={isUpdate ? 'Update' : 'Add new user'}
      >
        <PermissionForm
          onCloseModal={() => setModalOpen(false)}
          isUpdate={isUpdate}
          user={user}
          projectId={id}
          push={history.push}
          onSubmit={set}
          id={id}
        />
      </Modal>
    </div>
  )
}

export default ProjectPermissionPage
