import React, { useState } from 'react'
import ReactMde from 'react-mde'
import { Field } from 'formik'
import * as Showdown from 'showdown'
import 'react-mde/lib/styles/css/react-mde-all.css'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
})

export function MarkDownField ({ name, minHeight = '60vh', ...rest }) {
  const [selectedTab, setSelectedTab] = useState('write')

  return (
    <Field
      name={name}
      render={({ field, form: { setFieldValue } }) => (
        <ReactMde
          {...field}
          onChange={data => setFieldValue(name, data)}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          minEditorHeight={minHeight}
          generateMarkdownPreview={markdown =>
            Promise.resolve(converter.makeHtml(markdown))
          }
          {...rest}
        />
      )}
    />
  )
}
export default MarkDownField
