import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SignInForm from 'forms/SignInForm'
import GoogleAuthButton from 'components/GoogleAuthButton'
import Toast from 'components/Toast'
import { app } from 'utils/firebase'
import { Link } from 'react-router-dom'

const auth = app.auth()

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  pageContainer: {
    height: '100vh',
    backgroundSize: 'cover',
    backdropFilter: 'blur(6px)',
    backgroundColor: '#f9f9f9'
  },

  innerContainer: {
    border: '1px solid #d8dee2',
    padding: '30px !important',
    height: '100%',

    [breakpoints.up('sm')]: {
      borderRadius: 12,
      height: 'unset',

      '& > form': {
        width: '80%',
        margin: '0 auto'
      }
    }
  },

  headerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > p': {
      fontSize: '2rem',
      fontWeight: 500,
      marginLeft: 8
    },

    marginTop: 70,
    marginBottom: 24,

    [breakpoints.up('sm')]: {
      marginTop: 0
    }
  },

  newUserSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > a': {
      marginLeft: 4,
      color: '#4183c4',
      textDecoration: 'none'
    }
  },

  externalAuthSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,

    [breakpoints.down('xs')]: {
      marginBottom: 16
    }
  }
}))

const SignInPage = ({ history }) => {
  const classes = useStyles()

  const [error, setError] = useState(null)

  const handleLogin = ({ email, password }) => {
    return auth.signInWithEmailAndPassword(email, password)
      .then(({ user }) => user)
      .catch(error => {
        setError(error.message)

        throw error
      })
  }

  auth.onAuthStateChanged(async (user) => {
    if (user) {
      history.push('/')
    }
  })

  return (
    <Grid
      className={classes.pageContainer}
      container
      justify='center'
      alignItems='center'
    >
      <Grid item xs={12} sm={4} className={classes.innerContainer}>
        <div className={classes.headerSection}>
          <p>Documentation</p>
        </div>

        <div className={classes.externalAuthSection}>
          <GoogleAuthButton setError={setError} />
        </div>

        <SignInForm onSubmit={handleLogin} />

        <p className={classes.newUserSection}>New user?<Link to='/sign_up'> Create an account.</Link></p>
        <Toast
          message={error}
          onClear={() => setError('')}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          autoHideDuration={10000}
        />
      </Grid>

    </Grid>
  )
}

export default SignInPage
