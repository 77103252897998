import React, { useState } from 'react'
import { Form } from 'formik'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SelectInput from 'components/inputs/SelectInput'
import Toast from 'components/Toast'
import arrayToOptions from 'utils/arrayToOptions'
import { app } from 'utils/firebase'

import Field from 'fields/Field'

const db = app.firestore()

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '500px'
  },

  buttonContainer: {
    float: 'right'
  }
}))

const PermissionForm = ({ isUpdate, onCloseModal, projectId, values, handleSubmit, setFieldValue }) => {
  const classes = useStyles()
  const isValid = values.email && values.role
  const [error, setError] = useState(null)
  const permissionRef = db.collection(`projects/${projectId}/permissions`)

  async function handleOnClick () {
    try {
      if (isUpdate) {
        permissionRef.doc(values.id).update({
          role: values.role
        })

        handleSubmit()
      }
      else {
        await permissionRef.where('email', '==', values.email)
          .get()
          .then(snapshot => {
            if (snapshot.empty) {
              checkUser()
            }

            snapshot.forEach(() => {
              setError('Already have account on this project')
            })
          })
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  async function checkUser () {
    const userRef = db.collection('users')

    try {
      await userRef.where('email', '==', values.email)
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            setError('No users found')
          }

          snapshot.forEach(doc => {
            const user = doc.data()

            setFieldValue('userId', doc.id)

            permissionRef.doc(doc.id).set({
              userRef: db.doc('users/' + doc.id),
              email: user.email,
              id: user.uid,
              role: values.role
            })

            handleSubmit()
          })
        })
    }
    catch (error) {
      console.log(error)
    }
  }

  return (
    <Form className={classes.root}>
      <Grid container spacing={3} component={Form}>
        <Grid item xs={8}>
          {
            isUpdate ? <h3>{values.email}</h3> : (
              <Field
                name='email'
                noLabel
                type='email'
                variant='standard'
              />
            )
          }
        </Grid>

        <Grid item xs={4}>
          <Field
            name='role'
            noLabel
            variant='standard'
            component={SelectInput}
            isClearable={false}
            options={arrayToOptions(['Owner', 'Editor', 'Viewer'])}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleOnClick}
            disabled={!isValid}
          >
            Confirm
          </Button>

          <Button onClick={onCloseModal}>Cancel</Button>
        </Grid>
      </Grid>

      <Toast
        message={error}
        onClear={() => setError('')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={10000}
      />
    </Form>
  )
}

export default PermissionForm
