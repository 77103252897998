import React from 'react'
import CircularLoader from 'components/CircularLoader'

import { Grid, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useResource } from 'hooks/resources'
import userSchema from 'schemas/userSchema'
import { useAuth } from 'contexts/AuthContext'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  avatar: {
    width: 150,
    height: 150
  }
}))

function ProfileOverviewPage () {
  const classes = useStyles()
  const { currentUser } = useAuth()
  const [user, { loading }] = useResource(`users/${currentUser.uid}`, userSchema, currentUser.uid)

  return (
    <>
      {
        loading ? (
          <Grid item xs={12}>
            <CircularLoader />
          </Grid>
        ) : (
          <Grid container className={classes.root}>
            <Grid item xs={12} sm={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Avatar
                    src={user.photoURL}
                    alt='avatar'
                    className={classes.avatar}
                  />
                </Grid>

                <Grid item xs={12}>
                  email : {user.email}
                </Grid>

                <Grid item xs={12}>
                  username: {user.displayName}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </>
  )
}

export default ProfileOverviewPage
