import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { PageHeader } from 'components/navigation/PageHeader'
import ProjectForm from 'forms/ProjectForm'
import CircularLoader from 'components/CircularLoader'
import { Tab, IconButton } from '@material-ui/core'

import { useAuth } from 'contexts/AuthContext'
import { useResourceAction, useResource } from 'hooks/resources'
import { projectSchema } from 'schemas'
import {
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons'

function ProjectEditPage ({ history }) {
  const [tabSelected, setTabSelected] = useState('overview')
  const { currentUser } = useAuth()

  const { id } = useParams()
  const { set } = useResourceAction(projectSchema)
  const [project, { loading }] = useResource(`projects/${id}`, projectSchema, id)

  delete project.id

  return (
    <div className='page'>
      <PageHeader
        title={`Editing ${project.name}`}
        top={64}
        leftIconButton={
          <IconButton component={Link} to={`/projects/${id}`}>
            <ChevronLeftIcon />
          </IconButton>
        }
      >
        <Tab label='Overview' onClick={() => setTabSelected('overview')} />
        <Tab label='Guides' onClick={() => setTabSelected('guide')} />
        <Tab label='Reference' onClick={() => setTabSelected('reference')} />
        <Tab label='Examples' onClick={() => setTabSelected('example')} />
      </PageHeader>

      {
        loading ? <CircularLoader />
          : <ProjectForm uid={currentUser.uid} tab={tabSelected} project={project} push={history.push} onSubmit={set} id={id} />
      }
    </div>
  )
}

export default ProjectEditPage
