import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#202124'
    },
    secondary: {
      main: '#8ac004'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    }
  },
  textArea: {
    resize: 'vertical',
    width: '100%',
    border: '1px solid rgba(34, 36, 38, 0.15)',
    borderRadius: '5px',
    minHeight: '100px',
    padding: '10px 14px',

    '&:focus': {
      borderColor: '#85b7d9',
      outline: 'none'
    }
  }
})

export default theme
