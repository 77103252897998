import React from 'react'
import { connect, getIn } from 'formik'
import Input from 'components/inputs/Input'

export function FieldObject ({ name, noLabel, label, formik, value, ...rest }) {
  const values = getIn(formik.values, name)

  function handleInputChange (e, oldKey) {
    const newKey = e.target.value

    let newWordsObject = {}

    Object.keys(values).forEach(key => {
      if (key === oldKey) {
        const newPair = { [newKey]: values[oldKey] }
        newWordsObject = { ...newWordsObject, ...newPair }
      }
      else {
        newWordsObject = { ...newWordsObject, [key]: values[key] }
      }
    })

    formik.setFieldValue(name, newWordsObject)
  }

  return (
    <div>
      {!noLabel && <label>{label}</label>}

      <Input label={label} {...rest} value={value} onChange={e => handleInputChange(e, value)} />
    </div>

  )
}

export default connect(FieldObject)
