import React from 'react'
import { useParams } from 'react-router-dom'
import CircularLoader from 'components/CircularLoader'

import { useResource } from 'hooks/resources'
import { projectSchema } from 'schemas'
import SwaggerUI from 'swagger-ui-react'

import 'swagger-ui-react/swagger-ui.css'

function ProjectReferencePage () {
  const { id } = useParams()
  const [project, { loading }] = useResource(`projects/${id}`, projectSchema, id)

  return (
    <div>
      {
        loading ? <CircularLoader />
          : <SwaggerUI spec={project.json_data} />
      }
    </div>
  )
}

export default ProjectReferencePage
