import React from 'react'
import clsx from 'clsx'
import { Error, Close } from '@material-ui/icons'
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles1 = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function MySnackbarContentWrapper(props) {
  const classes = useStyles1()
  const { className, message, onClose, ...other } = props

  return (
    <SnackbarContent
      className={clsx(classes.error, className)}
      message={
        <span id='client-snackbar' className={classes.message}>
          <Error className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key='close' color='inherit' onClick={onClose}>
          <Close className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

export default function CustomizedSnackbars({ message, onClear, ...rest }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    onClear()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(message)}
      autoHideDuration={7000}
      onClose={handleClose}
      {...rest}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        message={message}
      />
    </Snackbar>
  )
}
