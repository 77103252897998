import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import { Grid } from '@material-ui/core'
import { connect, getIn } from 'formik'
import { Tabs, TabPane } from 'components/Tabs'
import EditorFormattedField from 'fields/EditorFormattedField'
import EditorField from 'fields/EditorField'
import SortableFieldArray from 'fields/SortableFieldArray'
import ServerField from 'modules/projects/fields/ServerField'
import SecurityField from 'modules/projects/fields/SecurityField'
import TagField from 'modules/projects/fields/TagField'
import SchemasField from 'modules/projects/fields/SchemasField'
import PathField from 'modules/projects/fields/PathField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  swaggerContainer: {
    maxWidth: 960
  }
}))

export function ProjectReferenceField (props) {
  const classes = useStyles()
  const { name, formik } = props
  const values = getIn(formik.values, name)

  return (
    <Grid container>
      <Grid item xs={6} sm={6}>
        <Tabs
          contained
          scrollButtons='off'
        >
          <TabPane label='Info'>
            <EditorFormattedField name='json_data' />
          </TabPane>

          <TabPane label='Paths'>
            <PathField name='json_data.paths' />
          </TabPane>

          <TabPane label='Server'>
            <SortableFieldArray
              name='json_data.servers'
              component={ServerField}
              newItemText='Add new server'
              noLabel
            />
          </TabPane>

          <TabPane label='Schemas'>
            <SchemasField name='json_data.components.schemas' />
          </TabPane>

          <TabPane label='Security'>
            <SecurityField name='json_data.components.securitySchemes' />
          </TabPane>

          <TabPane label='Tag'>
            <SortableFieldArray
              name='json_data.tags'
              component={TagField}
              newItemText='Add new server'
              noLabel
            />
          </TabPane>

          <TabPane label='Import / Export'>
            <EditorField name='json_data' />
          </TabPane>
        </Tabs>
      </Grid>

      <Grid item xs={6} sm={6} className={classes.swaggerContainer}>
        <SwaggerUI spec={values} />
      </Grid>
    </Grid>
  )
}

export default connect(ProjectReferenceField)
