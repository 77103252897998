import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@material-ui/core'

export function Modal ({
  children,
  component,
  trigger,
  isOpen,
  onCloseModal,
  title,
  cancelText,
  submitText,
  onCancel,
  onSubmit,
  ...rest
}) {
  function handleCancel (e) {
    onCancel && onCancel(e)
  }

  function handleSubmit (e) {
    onSubmit && onSubmit(e)
  }

  return (
    <>
      {trigger}

      <Dialog
        open={isOpen}
        onClose={onCloseModal}
        {...rest}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          {children || component}
        </DialogContent>

        <DialogActions>
          {
            onCancel && (
              <Button onClick={handleCancel} color='secondary'>
                {cancelText}
              </Button>
            )
          }

          {
            onSubmit && (
              <Button onClick={handleSubmit} color='primary'>
                {submitText}
              </Button>
            )
          }
        </DialogActions>
      </Dialog>
    </>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  component: PropTypes.node,
  trigger: PropTypes.node,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,

  title: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

export default Modal
