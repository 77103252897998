import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Tabs as MuiTabs } from '@material-ui/core'

import styles from './TabsStyles'

export class Tabs extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    contained: PropTypes.bool,
    currentTab: PropTypes.number,
    onTabChange: PropTypes.func,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)

    this.handleChangeTab = this.handleChangeTab.bind(this)

    this.state = {
      currentTab: 0
    }
  }

  componentDidMount () {
    this.props.currentTab && this.setState({ currentTab: this.props.currentTab })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.currentTab !== this.props.currentTab) {
      this.handleChangeTab('', this.props.currentTab)
    }
  }

  handleChangeTab (e, currentTab) {
    this.setState({ currentTab })

    this.props.onTabChange && this.props.onTabChange()
  }

  renderTabs () {
    const { children, classes, ...rest } = this.props
    const filteredChildren = children.filter(child => child instanceof Object)

    return (
      <MuiTabs
        key={this.state.currentTab}
        value={this.state.currentTab}
        onChange={this.handleChangeTab}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        classes={classes}
        {...rest}
      >
        {filteredChildren}
      </MuiTabs>
    )
  }

  renderPane () {
    const { children } = this.props
    const filteredChildren = children.filter(child => child instanceof Object)

    return (
      React.Children.map(filteredChildren, (child, i) => (
        this.state.currentTab === i && child && child.props.children
      ))
    )
  }

  render () {
    const { classes } = this.props

    return (
      this.props.contained ? (
        <div className={classes.card}>
          {this.renderTabs()}
          <div className={classes.container}>
            {this.renderPane()}
          </div>
        </div>
      ) : (
        <>
          {this.renderTabs()}
          {this.renderPane()}
        </>
      )
    )
  }
}

export default withStyles(styles)(Tabs)
