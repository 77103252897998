import React from 'react'
import { Link } from 'react-router-dom'
import { Tab } from '@material-ui/core'

const NavItem = ({ to, ...rest }) => {
  return (
    <Tab component={Link} to={to} {...rest} />
  )
}

export default NavItem
