import { withFormik } from 'formik'

import PermissionForm from './PermissionForm'

const formikConfig = {
  mapPropsToValues: ({ user }) => ({
    role: user.role || 'Viewer',
    id: user.id || '',
    email: user.email || ''
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onCloseModal()
    setSubmitting(false)
  }
}

export default withFormik(formikConfig)(PermissionForm)
