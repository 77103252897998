import { withFormik } from 'formik'

import ProfileForm from './ProfileForm'

const formikConfig = {
  mapPropsToValues: ({ user = {} }) => ({
    displayName: user.displayName || '',
    photoURL: user.photoURL || ''
  }),

  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.id, 'users', values)
      .then(() => {
        props.push('/profile')
      })
      .catch(error => {
        setSubmitting(false)
        console.warn(error)
      })
  }
}

export default withFormik(formikConfig)(ProfileForm)
