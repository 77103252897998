import React from 'react'
import { Grid } from '@material-ui/core'
import MarkDownField from 'fields/MarkDownField'

import { makeStyles } from '@material-ui/core/styles'
import Field from 'fields/Field'

const useStyles = makeStyles(() => ({
  root: {
    padding: 16
  }
}))

export function EditorFormattedField ({ name, ...rest }) {
  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12}>
        <Field label='Title' name={`${name}.info.title`} />
      </Grid>

      <Grid item xs={12}>
        <Field label='Version' name={`${name}.info.version`} />
      </Grid>

      <Grid item xs={12}>
        <label>Description</label>

        <MarkDownField name={`${name}.info.description`} minHeight='15vh' />
      </Grid>

      <Grid item xs={12}>
        <Field label='Terms of services Url' name={`${name}.info.termsOfService`} />
      </Grid>

      <Grid item xs={12}>
        <Field label='External docs' name={`${name}.externalDocs.description`} />
      </Grid>

      <Grid item xs={12}>
        <Field label='Documentation Url' name={`${name}.externalDocs.url`} />
      </Grid>

      <h3>Contact</h3>

      <Grid item xs={12}>
        <Field label='Name' name={`${name}.info.contact.name`} />
      </Grid>

      <Grid item xs={12}>
        <Field label='Url' name={`${name}.info.contact.url`} />
      </Grid>

      <Grid item xs={12}>
        <Field label='Email' name={`${name}.info.contact.email`} />
      </Grid>

      <h3>License</h3>

      <Grid item xs={12}>
        <Field label='Name' name={`${name}.info.license.name`} />
      </Grid>

      <Grid item xs={12}>
        <Field label='Url' name={`${name}.info.license.url`} />
      </Grid>

    </Grid>
  )
}

export default EditorFormattedField
