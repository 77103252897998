import { withFormik } from 'formik'

import SignUpForm from './SignUpForm'

const formikConfig = {
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values)
      .then(async user => {
        return user
      })
      .catch(error => {
        console.error('ERROR', error)
        setSubmitting(false)
      })
  }
}

export default withFormik(formikConfig)(SignUpForm)
