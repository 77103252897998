import { normalize } from 'normalizr'

import {
  SYNC_RESOURCE,
  SYNC_COLLECTION
} from 'constants/resources'

const initialState = {}

const resourcesReducer = (state = initialState, { type, payload, schema, snapshot, cacheKey }) => {
  let normalizedData

  switch (type) {
    case SYNC_RESOURCE:
      normalizedData = normalize(payload.doc, payload.schema)

      return {
        ...state,
        ...normalizedData.entities
      }

    case SYNC_COLLECTION:
      normalizedData = normalize(payload.docs, [payload.schema])

      return {
        ...state,
        ...normalizedData.entities
      }
    default:
      return state
  }
}

export default resourcesReducer
