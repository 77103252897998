import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Select from 'react-select'
import { get } from 'lodash'

export class SelectInput extends React.Component {
  static propTypes = {
    options: PropTypes.oneOfType([
      PropTypes.array,
      ImmutablePropTypes.list
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ]),
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    onChange: PropTypes.func
  }

  static defaultProps = {
    options: [],
    isClearable: true
  }

  render () {
    const { onChange, options, value, isMulti, isClearable, ...rest } = this.props

    const customStyles = {
      menuPortal: base => ({ ...base, zIndex: 9999 }),
      control: (provided) => ({
        ...provided,
        padding: 6
      })
    }

    return (
      <Select
        options={options}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        styles={customStyles}
        isClearable={isClearable}
        {...rest}
        onChange={data => (
          onChange(isMulti ? data.map(option => option.value) : get(data, 'value', ''))
        )}
        value={options.find(option => option.value === value)}
      />
    )
  }
}

export default SelectInput
