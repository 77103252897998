import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Tabs, Grid } from '@material-ui/core'
import Sticky from 'react-stickynode'
import { Button } from 'components/buttons'
import { ResponsiveMenu, ResponsiveMenuItem } from 'components/menus/ResponsiveMenu'

import NavItem from './NavItem'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    backgroundColor: '#f6f7f9'
  },
  primaryActions: {
    gridArea: 'primary-actions',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      justifyContent: 'space-between'
    }
  },

  gridContainer: {
    display: 'flex'
  },

  linkText: {
    color: '#4183c4',
    textDecoration: 'none'
  },

  actions: {
    gridArea: 'primary-actions',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      justifyContent: 'space-between'
    }
  },

  title: {
    marginTop: 8,
    padding: theme.spacing(0, 2),
    marginBottom: 8
  },

  stickyTabs: {
    backgroundColor: '#f6f7f9'
  },

  leftIconContainer: {
    gridArea: 'left-icon',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)
  },

  rightIconContainer: {
    gridArea: 'primary-actions',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      justifyContent: 'space-between'
    }
  }
}))

const PageHeader = ({ title, rightIconButton, leftIconButton, top, children, primaryActions, actions, ...rest }) => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(0)
  const [sticky, setSticky] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    React.Children.forEach(children, ({ props: childProps }, i) => (
      childProps.to === pathname && setCurrentTab(i)
    ))
  }, [children, pathname])

  function handleTabChange (e, tab) {
    setCurrentTab(tab)
  }

  function handleStickyChange (status) {
    setSticky(status.status === Sticky.STATUS_FIXED)
  }

  let tabs = (
    <Tabs
      value={currentTab}
      indicatorColor='primary'
      textColor='primary'
      onChange={handleTabChange}
    >
      {children}
    </Tabs>
  )

  if (sticky) {
    tabs = (
      <Paper square className={classes.stickyTabs}>
        {tabs}
      </Paper>
    )
  }

  return (
    <Paper square className={classes.root} {...rest}>
      <Grid container>

        <Grid item xs={8} className={classes.gridContainer}>
          {
            leftIconButton && (
              <>
                {leftIconButton}
              </>
            )
          }
          <h1 className={classes.title}>{title}</h1>

        </Grid>

        <Grid item xs={4}>
          {
            primaryActions && (
              <div className={classes.primaryActions}>
                {
                  primaryActions.map(({ avatar, content, onClick, to, className, ...rest }) => (
                    <Button
                      key={content}
                      type='button'
                      variant='contained'
                      color='primary'
                      onClick={onClick}
                      component={to && Link}
                      to={to}
                      {...rest}
                    >
                      {avatar && React.cloneElement(avatar, { fontSize: 'small' })}
                      {content}
                    </Button>
                  ))
                }
              </div>
            )
          }

          {
            actions && (
              <div className={classes.actions}>
                <ResponsiveMenu>
                  {
                    actions.map(action => (
                      <ResponsiveMenuItem
                        {...action}
                        key={action.content}
                        onClick={action.onClick}
                        component={action.to && Link}
                        to={action.to}
                      />
                    ))
                  }
                </ResponsiveMenu>
              </div>
            )
          }

          {/* {
            rightIconButton && (
              <div className={classes.rightIconContainer}>
                {rightIconButton}
              </div>
            )
          } */}
        </Grid>

      </Grid>

      <Sticky top={top} onStateChange={handleStickyChange} innerZ={1000}>
        {tabs}
      </Sticky>
    </Paper>
  )
}

export { PageHeader, NavItem }

export default PageHeader
