import React from 'react'
import { Form } from 'formik'
import { Grid } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import MarkDownField from 'fields/MarkDownField'
import Field from 'fields/Field'
import ProjectReferenceField from 'modules/projects/fields/ProjectReferenceField'

import { Button } from 'components/buttons'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  padding: {
    padding: 16
  },
  button: {
    margin: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1)
  }
}))

export function ProjectForm ({ tab, isSubmitting }) {
  const classes = useStyles()

  return (
    <Grid container component={Form}>
      <Grid item xs={12} sm={12}>

        {
          ({
            overview: (
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.margin}>
                  <Field nolabel name='name' />
                </Grid>

                <Grid item xs={12}>
                  <MarkDownField name='overview' />
                </Grid>
              </Grid>
            ),
            guide: (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MarkDownField name='guide' />
                </Grid>
              </Grid>
            ),
            reference: (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ProjectReferenceField name='json_data' />
                </Grid>
              </Grid>
            ),
            example: (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MarkDownField name='example' />
                </Grid>
              </Grid>
            )
          })[tab]
        }

        <Grid item xs={12} container justify='flex-end'>
          <Button
            className={classes.button}
            type='submit'
            variant='contained'
            color='primary'
            disabled={isSubmitting}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProjectForm
