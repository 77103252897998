import React from 'react'
import { Grid } from '@material-ui/core'
import SortableFormSection from 'fields/SortableFieldArray/SortableFormSection'
import { connect, getIn } from 'formik'

import Field from 'fields/Field'

export function TagField ({ name, formik, ...rest }) {
  const values = getIn(formik.values, name)

  return (
    <SortableFormSection title={values.name} {...rest}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field label='name' name={`${name}.name`} />
        </Grid>

        <Grid item xs={12}>
          <Field label='Description' name={`${name}.description`} />
        </Grid>

        <Grid item xs={12}>
          <Field label='ExternalDocs' name={`${name}.externalDocs.description`} />
        </Grid>

        <Grid item xs={12}>
          <Field label='ExternalDocs Url' name={`${name}.externalDocs.url`} />
        </Grid>
      </Grid>
    </SortableFormSection>
  )
}

export default connect(TagField)
