import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardActions, Button, Collapse, Typography } from '@material-ui/core'
import { ExpandMore, DragIndicator, Delete } from '@material-ui/icons'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import { withStyles } from '@material-ui/core/styles'
import { connect, getIn } from 'formik'
import { compose } from 'redux'
import classNames from 'clsx'

import styles from './SortableFormSectionStyles'

const DragHandle = SortableHandle(props => (
  <DragIndicator {...props} />
))

export class SortableFormSection extends Component {
  static propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    onRemove: PropTypes.func,
    children: PropTypes.node,

    classes: PropTypes.object,
    formik: PropTypes.object
  }

  constructor (props) {
    super(props)

    this.state = {
      expanded: false
    }

    this.handleToggleExpand = this.handleToggleExpand.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  handleToggleExpand () {
    this.setState(({ expanded }) => ({ expanded: !expanded }))
  }

  handleRemove (e) {
    const { name, formik, onRemove } = this.props
    const value = getIn(formik.values, name)

    onRemove && onRemove(e, { value })
  }

  render () {
    const { title, name, children, classes, formik } = this.props

    const values = getIn(formik.values, name)

    return (
      <Card className={classes.root}>
        <CardContent classes={{ root: classes.title }}>
          <DragHandle className={classes.dragHandler} />

          <Button
            onClick={this.handleToggleExpand}
            fullWidth
            classes={{
              root: classes.clickableArea,
              label: classes.titleContent
            }}
          >
            <Typography variant='h6' className={classes.titleText}>{title}</Typography>

            <ExpandMore
              className={classNames(classes.caret, {
                [classes.expanded]: this.state.expanded
              })}
            />
          </Button>
        </CardContent>

        <Collapse in={this.state.expanded} unmountOnExit>
          <CardContent>
            {children}
          </CardContent>

          <CardActions className={classes.cardActions}>
            <Button
              className={classNames(classes.removeButton, {
                [classes.activeRemoveButton]: values._destroy
              })}
              onClick={this.handleRemove}
            >
              <Delete className={classes.leftIcon} />
              Remove
            </Button>
          </CardActions>
        </Collapse>
      </Card>
    )
  }
}

export default compose(
  connect,
  withStyles(styles),
  SortableElement
)(SortableFormSection)
