import React from 'react'
import { Typography } from '@material-ui/core'
import { Field as FormikField, ErrorMessage, getIn } from 'formik'
import { isUndefined } from 'lodash'
import Input from 'components/inputs/Input'

export function Field ({
  name, label, noLabel, required, hint, placeholder, fluid,
  render, component: Component, initialValue,
  ...rest
}) {
  const labelName = name.split('.').pop()

  function renderInput ({ field, form, meta }) {
    if (render) {
      return render({ field, form })
    }
    else if (Component) {
      return (
        <Component
          {...field}
          value={!isUndefined(field.value) ? field.value : initialValue}
          placeholder={placeholder || label || name}
          error={getIn(form.touched, name) && getIn(form.errors, name)}
          onChange={(eventOrValue, data) => {
            if (!isUndefined(eventOrValue) && eventOrValue.nativeEvent) {
              return !isUndefined(data) ? form.setFieldValue(name, data.value) : field.onChange(eventOrValue)
            }
            else {
              return form.setFieldValue(name, eventOrValue)
            }
          }}
          onBlur={(eventOrValue, data) => {
            if (!isUndefined(eventOrValue) && eventOrValue.nativeEvent) {
              return !isUndefined(data) ? form.setFieldTouched(name, true) : field.onBlur(eventOrValue)
            }
            else {
              return form.setFieldTouched(name, true)
            }
          }}
          {...rest}
        />
      )
    }
  }

  return (
    <FormikField name={name}>
      {(renderProps) => (
        <div>
          {!noLabel && <label>{label || labelName}</label>}

          {renderInput(renderProps)}

          {hint && <p>{hint || name}</p>}

          <ErrorMessage
            name={name}
            render={(message) => (
              message ? <Typography color='error'>{message}</Typography> : null
            )}
          />
        </div>
      )}
    </FormikField>
  )
}

Field.defaultProps = {
  component: Input,
  initialValue: '',
  fluid: false
}

export default Field
