export default theme => ({
  root: {
    minHeight: 53,
    minWidth: 80,
    [theme.breakpoints.up('md')]: {
      minWidth: 120
    },
    '&:hover': {
      backgroundColor: 'rgba(29, 161, 242, 0.1)',
      '& .MuiTab-label': {
        color: '#1da1f2'
      }
    },
    '&$selected': {
      '& *': {
        color: '#1da1f2'
      }
    }
  },
  selected: {},
  textColorInherit: {
    opacity: 1
  },
  wrapper: {
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 700,
    color: '#657786'
  }
})
