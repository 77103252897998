export default theme => ({
  root: {
    width: '100%',
    borderBottom: '1px solid #E6ECF0'
  },

  card: {
    border: '1px solid #ddd',
    height: '100%'
  },

  container: {
    padding: 16
  },

  indicator: {
    backgroundColor: '#1da1f2'
  }
})
