import React, { createContext, useContext, useState } from 'react'
import { CircularProgress } from '@material-ui/core'

import { app } from 'utils/firebase'

const auth = app.auth()

export const AuthContext = createContext()

const Loader = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}
    >
      <CircularProgress
        size={230}
        thickness={2}
        style={{ position: 'absolute' }}
      />
    </div>
  )
}

export const AuthProvider = ({ children }) => {
  const [ready, setReady] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [error, setError] = useState('')

  auth.onAuthStateChanged(async (user) => {
    if (user) {
      setCurrentUser(user)

      setReady(true)
    }
    else {
      setCurrentUser(null)
      setReady(true)
    }
  })

  return (
    <AuthContext.Provider
      value={{
        error,
        setError,
        currentUser
      }}
    >
      {ready ? children : <Loader />}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
