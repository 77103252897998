import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PageHeader } from 'components/navigation/PageHeader'
import ProjectForm from 'forms/ProjectForm'
import { Tab, IconButton } from '@material-ui/core'

import { useResourceAction } from 'hooks/resources'
import { projectSchema } from 'schemas'
import { useAuth } from 'contexts/AuthContext'
import { app } from 'utils/firebase'
import {
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons'

const db = app.firestore()

function ProjectNewPage ({ history }) {
  const [tabSelected, setTabSelected] = useState('overview')
  const { currentUser } = useAuth()
  const { create } = useResourceAction(projectSchema)

  function handleAfterSubmit (projectId) {
    const permissionRef = db.collection(`projects/${projectId}/permissions`)

    permissionRef.doc(currentUser.uid).set({
      userRef: db.doc('users/' + currentUser.uid),
      email: currentUser.email,
      id: currentUser.uid,
      role: 'Owner'
    })
  }

  return (
    <div className='page'>
      <PageHeader
        title='Add new project'
        top={64}
        leftIconButton={
          <IconButton component={Link} to='/'>
            <ChevronLeftIcon />
          </IconButton>
        }
      >
        <Tab label='Overview' onClick={() => setTabSelected('overview')} />
        <Tab label='Guides' onClick={() => setTabSelected('guide')} />
        <Tab label='Reference' onClick={() => setTabSelected('reference')} />
        <Tab label='Examples' onClick={() => setTabSelected('example')} />
      </PageHeader>

      <ProjectForm isCreate afterSubmit={handleAfterSubmit} uid={currentUser.uid} tab={tabSelected} push={history.push} onSubmit={create} />
    </div>
  )
}

export default ProjectNewPage
